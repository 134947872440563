<template>
    <div class="container u-f-ajb u-d">
        <Header :type="2" :current="current" @onClickTab="onClickTab"></Header>

        <div v-show="current === 3" class="main u-f-ajc u-d u-f1">
            <div class="shadow u-f-ajc u-d">
                <div class="div-form-title">艺术商演需求</div>
                <el-form id="show" name="show" :model="showForm" :rules="showRules" ref="showForm" label-width="100px" size="medium" class="form">
                    <el-form-item label="商演需求：" prop="customNeed">
                        <el-input
                            type="textarea"
                            v-model="showForm.customNeed"
                            :autosize="{ minRows: 5, maxRows: 8 }"
                            placeholder="可以输入您的需求信息，如：演出地点、演出时间等…"
                            maxlength="100"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="联系人：" prop="contactPerson">
                        <el-input v-model="showForm.contactPerson" placeholder="请填写联系人姓名" maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="手机号：" prop="contactMobile">
                        <el-input v-model.number="showForm.contactMobile" placeholder="请填写联系人手机号" maxlength="18" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="verCode">
                        <div class="u-f-ajb">
                            <el-input v-model="showForm.verCode" placeholder="请输入短信验证码" maxlength="6" show-word-limit></el-input>
                            <el-button style="margin-left: 20px;" :disabled="sendDuration != 60" type="primary" v-preventReClick @click="sentCode('showForm')">
                                {{ sendDuration == 60 ? '获取验证码' : '再次发送(' + sendDuration + ')' }}
                            </el-button>
                        </div>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" v-preventReClick @click="submitForm('showForm')">发起申请</el-button>
                        <el-button v-preventReClick @click="resetForm('showForm')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <Footer :type="2"></Footer>
    </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import { Message } from 'element-ui';
import { getCode , applyShow } from '@/utils/API.js';

export default {
    name: 'ApplyShow',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            current: 3,
            sendTimer: null,
            sendDuration: 60,
            
            showForm: {},
            showRules: {
                customNeed: [{ required: true, message: '请输入您的需求', trigger: 'blur' }, { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }],
                contactPerson: [{ required: true, message: '请填写联系人姓名', trigger: 'blur' }, { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }],
                contactMobile: [{ required: true, pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: '请输入正确的手机号码', trigger: 'blur' }],
                verCode: [{ required: true, pattern: /^\d{6}$/, message: '请输入短信验证码', trigger: 'blur' }]
            }
        };
    },
    methods: {
        onClickTab(currentIndex) {
            this.current = currentIndex;
            if (this.current === 0) {
                this.$router.replace({ name: 'Home' });
            } else if (this.current === 1) {
                this.$router.replace({ name: 'About', params: { index: currentIndex } });
            } else if (this.current === 2) {
                this.$router.replace({ name: 'ApplyWebsit', params: { index: currentIndex } });
            } else if (this.current === 3) {
                this.$router.replace({ name: 'ApplyShow', params: { index: currentIndex } });
            }
        },
        sentCode(formName) {
            console.log('点击了');
            if (this.sendDuration != 60) return;
            this.$refs[formName].validateField('contactMobile', valid => {
                if (!valid) {
                    getCode({ phone: this.showForm.contactMobile, isNeedUserVerPhone: false }, res => {
                        if (res.code == 200) {
                            Message({
                                message: '验证码发送成功，请注意查收。',
                                type: 'success',
                                duration: 5 * 1000
                            });
                        }
                    }).catch(() => {});
                    this.sendTimer = setInterval(() => {
                        this.sendDuration--;
                        if (this.sendDuration <= 0) {
                            clearInterval(this.sendTimer);
                            this.sendTimer = null;
                            this.sendDuration = 60;
                        }
                    }, 1000);
                }
            });
        },
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    applyShow(this.showForm).then(() => {
                        Message({
                            message: '商演申请成功',
                            type: 'success',
                            duration: 5 * 1000
                        });
                        this.resetForm(formName);
                    });
                }
            });
        },
        resetForm(formName) {
            this.showForm = {};
            if (this.sendTimer) {
                clearInterval(this.sendTimer);
                this.sendTimer = null;
                this.sendDuration = 60;
            }

            this.$refs[formName].resetFields();
        }
    },
    created() {
        this.current = this.$route.params.index || 3;
    }
};
</script>

<style type="text/css" scoped="scoped">
/* 在宽小于或等于 1000 像素的屏幕上 */
@media screen and (max-width: 1000px) {
    .main {
        width: 100% !important;
    }
    .shadow {
        width: 100% !important;
        height: 100% !important;
        box-shadow: none;
        border-radius: 0;
    }
    .form {
        width: 90% !important;
    }
}
.container {
    height: 100%;
    background-color: #ffffff;
}
.main {
    font-size: 16px;
    width: 60%;
    color: #333333;
}
p {
    margin: 0;
}
.div-title {
    font-size: 36px;
    font-weight: 500;
    margin: 0 30px;
    margin-bottom: 30px;
}
.div-form-title {
    font-size: 24px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 40px;
}
.div-content {
    text-indent: 2em;
    margin: 0 30px;
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 36px;
}
.div-content2 {
    text-indent: 2em;
    margin: 0 30px;
    margin-top: 40px;
    font-size: 18px;
    line-height: 36px;
}
.shadow {
    width: 1000px;
    height: 760px;
    background: #ffffff;
    box-shadow: 1px 1px 10px 0px rgba(153, 153, 153, 0.5);
    border-radius: 8px;
}
.form {
    overflow: hidden;
    width: 580px;
}
</style>
